import NavPanel from "../global/NavPanel";
import "./index.css";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,
} from "react-bootstrap";
import Loader from "react-loader-spinner";
import { useState } from "react";
//
import {
  tiles,
  mixers,
  mosaics,
  bathtubs,
  accessories,
  furniture,
} from "../../helper/items";
import Footer from "../global/Footer";
//
const Collection = (props) => {
  const [active, setActive] = useState("AZUVI");
  const [modalShow, setModalShow] = useState(false);
  const [enlarged, setEnlarged] = useState("");

  const loaderContainer = {
    position: "absolute",
    top: "50%",
    left: "50%",
    mozTransform: "translateX(-50%) translateY(-50%)",
    webkitTransform: "translateX(-50%) translateY(-50%)",
    transform: "textDecorationThickness",
  };

  const showImages = () => {
    switch (props.location.pathname) {
      case "/collections/tiles": {
        return tiles.map((tile) => {
          if (tile.name === active) {
            return tile.images.map((image) => {
              return (
                <Col lg={4} className="pb-3">
                  <Card.Img
                    className="shadow"
                    style={{ height: "200px", width: "250px" }}
                    src={image.default}
                    alt="loading..."
                    loading="auto"
                    onClick={() => {
                      setModalShow(true);
                      setEnlarged(image.default);
                    }}
                  />
                </Col>
              );
            });
          }
        });
      }

      case "/collections/mixers": {
        return mixers.map((mixer) => {
          if (mixer.name === active) {
            return mixer.images.map((image) => {
              return (
                <Col lg={4} className="pb-3">
                  <Card.Img
                    className="shadow"
                    style={{ height: "200px", width: "250px" }}
                    src={image.default}
                    alt="loading..."
                    loading="auto"
                    onClick={() => {
                      setModalShow(true);
                      setEnlarged(image.default);
                    }}
                  />
                </Col>
              );
            });
          }
        });
      }

      case "/collections/tubs": {
        return bathtubs.map((bathtub) => {
          if (bathtub.name === active) {
            return bathtub.images.map((image) => {
              return (
                <Col lg={4} className="pb-3">
                  <Card.Img
                    className="shadow"
                    style={{ height: "200px", width: "250px" }}
                    src={image.default}
                    alt="loading..."
                    loading="auto"
                    onClick={() => {
                      setModalShow(true);
                      setEnlarged(image.default);
                    }}
                  />
                </Col>
              );
            });
          }
        });
      }

      case "/collections/mosaics": {
        return mosaics.map((mosaic) => {
          if (mosaic.name === active) {
            return mosaic.images.map((image) => {
              return (
                <Col lg={4} className="pb-3">
                  <Card.Img
                    className="shadow"
                    style={{ height: "200px", width: "250px" }}
                    src={image.default}
                    alt="loading..."
                    loading="auto"
                    onClick={() => {
                      setModalShow(true);
                      setEnlarged(image.default);
                    }}
                  />
                </Col>
              );
            });
          }
        });
      }

      case "/collections/accessories": {
        return accessories.map((accessory) => {
          if (accessory.name === active) {
            return accessory.images.map((image) => {
              return (
                <Col lg={4} className="pb-3">
                  <Card.Img
                    className="shadow"
                    style={{ height: "200px", width: "250px" }}
                    src={image.default}
                    alt="loading..."
                    loading="auto"
                    onClick={() => {
                      setModalShow(true);
                      setEnlarged(image.default);
                    }}
                  />
                </Col>
              );
            });
          }
        });
      }

      case "/collections/furniture": {
        return furniture.map((furnit) => {
          if (furnit.name === active) {
            return furnit.images.map((image) => {
              return (
                <Col lg={4} className="pb-3">
                  <Card.Img
                    className="shadow"
                    style={{ height: "200px", width: "250px" }}
                    src={image.default}
                    alt="loading..."
                    loading="auto"
                    onClick={() => {
                      setModalShow(true);
                      setEnlarged(image.default);
                    }}
                  />
                </Col>
              );
            });
          }
        });
      }

      default:
        return;
    }
  };

  const collectionMenu = () => {
    switch (props.location.pathname) {
      case "/collections/tiles":
        return (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Tiles</th>
              </tr>
            </thead>
            <tbody>
              {tiles.map((tile) => {
                return (
                  <tr
                    onClick={() => {
                      setActive(tile.name);
                      console.log(active);
                    }}
                  >
                    <td>{tile.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        );

      case "/collections/mixers":
        return (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Mixers</th>
              </tr>
            </thead>
            <tbody>
              {mixers.map((mixer) => {
                return (
                  <tr
                    onClick={() => {
                      setActive(mixer.name);
                      console.log(active);
                    }}
                  >
                    <td>{mixer.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        );
      case "/collections/mosaics":
        return (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Mosaics</th>
              </tr>
            </thead>
            <tbody>
              {mosaics.map((mosaic) => {
                return (
                  <tr
                    onClick={() => {
                      setActive(mosaic.name);
                      console.log(active);
                    }}
                  >
                    <td>{mosaic.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        );
      case "/collections/tubs":
        return (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Bathtubs</th>
              </tr>
            </thead>
            <tbody>
              {bathtubs.map((bathtub) => {
                return (
                  <tr
                    onClick={() => {
                      setActive(bathtub.name);
                      console.log(active);
                    }}
                  >
                    <td>{bathtub.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        );
      case "/collections/accessories":
        return (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Accessories</th>
              </tr>
            </thead>
            <tbody>
              {accessories.map((accessory) => {
                return (
                  <tr
                    onClick={() => {
                      setActive(accessory.name);
                      console.log(active);
                    }}
                  >
                    <td>{accessory.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        );

      case "/collections/furniture":
        return (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Furniture</th>
              </tr>
            </thead>
            <tbody>
              {furniture.map((furnit) => {
                return (
                  <tr
                    onClick={() => {
                      setActive(furnit.name);
                      console.log(active);
                    }}
                  >
                    <td>{furnit.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        );
      default:
        return (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </Table>
        );
    }
  };

  const MyModal = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img
            src={props.image}
            style={{
              width: "100%",
              height: "100%",
            }}
            alt="loading..."
          />
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: "#909090" }} onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Container fluid className="p-0">
      <NavPanel />
      <Row className="m-0">
        <Col xl={3} lg={4} md={4} sm={12} className="p-0">
          <Card>
            <Card.Body className="text-center p-0 m-0">
              {collectionMenu()}
            </Card.Body>
          </Card>
        </Col>
        <Col className="p-0" xl={9} lg={8} md={8} sm={12}>
          <Card>
            <Card.Body>
              <Row className="m-2 text-center">{showImages()}</Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <MyModal
        show={modalShow}
        image={enlarged}
        onHide={() => setModalShow(false)}
      />
      <Footer />
    </Container>
  );
};

export default Collection;
