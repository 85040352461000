import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import c1 from "../../assets/logo/logo1.png";
import c2 from "../../assets/logo/logo2.png";
import c3 from "../../assets/logo/logo3.png";
import c4 from "../../assets/logo/logo4.png";
import c5 from "../../assets/logo/logo5.png";
import c6 from "../../assets/logo/logo6.png";
import c7 from "../../assets/logo/logo7.png";
import c8 from "../../assets/logo/logo8.png";
import c9 from "../../assets/logo/logo9.png";
import c10 from "../../assets/logo/logo10.png";
import c11 from "../../assets/logo/logo11.png";
import c12 from "../../assets/logo/logo12.png";

import { useTranslation } from "react-i18next";

const Slider = () => {
  const { i18n, t } = useTranslation();
  const slide = {
    height: "125px",
  };

  const images = [
    { src: c1 },
    { src: c2 },
    { src: c3 },
    { src: c4 },
    { src: c5 },
    { src: c6 },
    { src: c7 },
    { src: c8 },
    { src: c9 },
    { src: c10 },
    { src: c11 },
    { src: c12 },
  ];

  const alignment = {
    textAlign: i18n.dir() === "ltr" ? "left" : "right",
  };

  return (
    <Container className="pb-5">
      <Row className="mb-5 pt-5">
        <Col>
          <h2 style={alignment}>
            <a href="/about-alsatari" style={{ color: "#2170B2" }}>
              {t("slider.alsatari")}
            </a>
          </h2>
          <p style={alignment}>
            {t("header.intro")}
            <Link to="/about-alsatari"> {t("header.link")}</Link>
          </p>
        </Col>
      </Row>
      <Row>
        {images.map((image, index) => {
          return (
            <Col
              xl={2}
              lg={2}
              md={3}
              sm={4}
              xs={6}
              key={index + 1}
              className="mb-4"
            >
              <Card className="shadow">
                <Card.Img style={slide} variant="top" src={image.src} />
                {/* <Card.ImgOverlay>
              <Card.Title></Card.Title>
            </Card.ImgOverlay> */}
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Slider;
