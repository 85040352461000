import Home from "../components/pages/Home";
import About from "../components/pages/About";
import Collection from "../components/pages/Collection";
import Projects from "../components/pages/Projects";
import Viewer from "../components/pages/Viewer";
import Promotion from "../components/pages/Promotion";

const publicRoutes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/about-alsatari",
    component: About,
  },
  {
    path: "/collections/:tag",
    component: Collection,
  },
  {
    path: "/projects",
    component: Projects,
  },
  {
    path: "/pdf/:doc",
    component: Viewer,
  },
  {
    path: "/promotions",
    component: Promotion,
  },
];

export default publicRoutes;
