const bath1 = require("../assets/bath/1.jpg");
const bath2 = require("../assets/bath/2.jpg");
const bath3 = require("../assets/bath/3.jpg");
const bath4 = require("../assets/bath/4.jpg");
const bath5 = require("../assets/bath/5.jpg");
const bath6 = require("../assets/bath/6.jpg");
const bath7 = require("../assets/bath/7.jpg");
const bath9 = require("../assets/bath/9.jpg");
const bath10 = require("../assets/bath/10.jpg");
const bath11 = require("../assets/bath/11.jpg");
const bath12 = require("../assets/bath/12.jpg");

const mosaics1 = require("../assets/mosaics/1.PNG");
const mosaics2 = require("../assets/mosaics/2.PNG");
const mosaics3 = require("../assets/mosaics/3.PNG");
const mosaics4 = require("../assets/mosaics/4.PNG");
const mosaics5 = require("../assets/mosaics/5.PNG");
const mosaics6 = require("../assets/mosaics/6.PNG");
const mosaics7 = require("../assets/mosaics/7.PNG");
const mosaics8 = require("../assets/mosaics/8.PNG");
const mosaics9 = require("../assets/mosaics/9.PNG");
const mosaics10 = require("../assets/mosaics/10.PNG");
const mosaics11 = require("../assets/mosaics/11.PNG");
const mosaics12 = require("../assets/mosaics/12.PNG");

const bathroomacc1 = require("../assets/braccess/1.png");
const bathroomacc2 = require("../assets/braccess/2.jpg");
const bathroomacc3 = require("../assets/braccess/3.jpg");
const bathroomacc4 = require("../assets/braccess/4.jpg");
const bathroomacc5 = require("../assets/braccess/5.jpg");
const bathroomacc6 = require("../assets/braccess/6.jpg");
const bathroomacc7 = require("../assets/braccess/7.jpg");
const bathroomacc8 = require("../assets/braccess/8.jpg");
const bathroomacc9 = require("../assets/braccess/9.jpg");
const bathroomacc10 = require("../assets/braccess/10.jpg");
const bathroomacc11 = require("../assets/braccess/11.jpg");
const bathroomacc12 = require("../assets/braccess/12.jpg");
const bathroomacc14 = require("../assets/braccess/14.jpg");
const bathroomacc15 = require("../assets/braccess/15.jpg");
const bathroomacc16 = require("../assets/braccess/16.jpg");
const bathroomacc17 = require("../assets/braccess/17.jpg");
const bathroomacc18 = require("../assets/braccess/18.jpg");
const bathroomacc19 = require("../assets/braccess/19.jpg");
const bathroomacc20 = require("../assets/braccess/20.jpg");
const bathroomacc21 = require("../assets/braccess/21.jpg");
const bathroomacc22 = require("../assets/braccess/22.jpg");
const bathroomacc23 = require("../assets/braccess/23.jpg");
const bathroomacc24 = require("../assets/braccess/24.jpg");
const bathroomacc25 = require("../assets/braccess/25.jpg");
const bathroomacc26 = require("../assets/braccess/26.jpg");

const itt1 = require("../assets/tiles/ITT1.PNG");
const itt2 = require("../assets/tiles/ITT2.PNG");
const itt3 = require("../assets/tiles/ITT3.PNG");
const itt4 = require("../assets/tiles/ITT4.PNG");
const itt5 = require("../assets/tiles/ITT5.PNG");
const itt6 = require("../assets/tiles/ITT6.PNG");
const itt7 = require("../assets/tiles/ITT7.PNG");
const itt8 = require("../assets/tiles/ITT8.PNG");
const itt9 = require("../assets/tiles/ITT9.PNG");
const itt10 = require("../assets/tiles/ITT10.PNG");
const itt11 = require("../assets/tiles/ITT11.PNG");
const itt12 = require("../assets/tiles/ITT12.PNG");

const alap1 = require("../assets/tiles/ALAPLANA1.PNG");
const alap2 = require("../assets/tiles/ALAPLANA2.PNG");
const alap3 = require("../assets/tiles/ALAPLANA3.PNG");
const alap4 = require("../assets/tiles/ALAPLANA4.PNG");
const alap5 = require("../assets/tiles/ALAPLANA5.PNG");
const alap6 = require("../assets/tiles/ALAPLANA6.PNG");
const alap7 = require("../assets/tiles/ALAPLANA7.PNG");
const alap8 = require("../assets/tiles/ALAPLANA8.PNG");
const alap9 = require("../assets/tiles/ALAPLANA9.JPG");

const mixers1 = require("../assets/mixers/DANIEL1.PNG");
const mixers2 = require("../assets/mixers/DANIEL2.PNG");
const mixers3 = require("../assets/mixers/DANIEL3.PNG");
const mixers4 = require("../assets/mixers/DANIEL4.PNG");
const mixers5 = require("../assets/mixers/DANIEL5.PNG");
const mixers6 = require("../assets/mixers/DANIEL6.PNG");
const mixers7 = require("../assets/mixers/DANIEL7.PNG");
const mixers8 = require("../assets/mixers/DANIEL8.PNG");
const mixers9 = require("../assets/mixers/DANIEL9.PNG");
const mixers10 = require("../assets/mixers/DANIEL10.PNG");
const mixers11 = require("../assets/mixers/DANIEL11.PNG");
const mixers12 = require("../assets/mixers/DANIEL12.PNG");
const mixers13 = require("../assets/mixers/DANIEL13.PNG");
const mixers14 = require("../assets/mixers/DANIEL14.PNG");
const mixers15 = require("../assets/mixers/DANIEL15.PNG");
const mixers16 = require("../assets/mixers/DANIEL16.PNG");

const furniture1 = require("../assets/furniture/1.jpg");
const furniture2 = require("../assets/furniture/2.jpg");
const furniture3 = require("../assets/furniture/3.jpg");
const furniture4 = require("../assets/furniture/4.jpg");
const furniture5 = require("../assets/furniture/5.PNG");
const furniture6 = require("../assets/furniture/6.PNG");
const furniture7 = require("../assets/furniture/7.PNG");
const furniture8 = require("../assets/furniture/8.PNG");
const furniture9 = require("../assets/furniture/9.PNG");
const furniture10 = require("../assets/furniture/10.PNG");
const furniture11 = require("../assets/furniture/11.PNG");
const furniture12 = require("../assets/furniture/12.PNG");

const arklam1 = require("../assets/arklam/1.PNG");
const arklam2 = require("../assets/arklam/2.PNG");
const arklam3 = require("../assets/arklam/3.PNG");
const arklam4 = require("../assets/arklam/4.PNG");
const arklam5 = require("../assets/arklam/5.PNG");
const arklam6 = require("../assets/arklam/6.PNG");
const arklam7 = require("../assets/arklam/7.PNG");
const arklam8 = require("../assets/arklam/8.PNG");
const arklam9 = require("../assets/arklam/9.PNG");
const arklam10 = require("../assets/arklam/10.PNG");
const arklam11 = require("../assets/arklam/11.PNG");
const arklam12 = require("../assets/arklam/12.PNG");
const arklam13 = require("../assets/arklam/13.PNG");
const arklam14 = require("../assets/arklam/14.PNG");
const arklam15 = require("../assets/arklam/15.PNG");
const arklam16 = require("../assets/arklam/16.PNG");

const azuvi1 = require("../assets/azuvi/1.png");
const azuvi2 = require("../assets/azuvi/2.png");
const azuvi3 = require("../assets/azuvi/3.png");
const azuvi4 = require("../assets/azuvi/4.png");
const azuvi5 = require("../assets/azuvi/5.png");
const azuvi6 = require("../assets/azuvi/6.png");
const azuvi7 = require("../assets/azuvi/7.png");
const azuvi8 = require("../assets/azuvi/8.png");
const azuvi9 = require("../assets/azuvi/9.png");
const azuvi10 = require("../assets/azuvi/10.png");
const azuvi11 = require("../assets/azuvi/11.png");
const azuvi12 = require("../assets/azuvi/12.png");

const emotion1 = require("../assets/emotion/1.PNG");
const emotion2 = require("../assets/emotion/2.PNG");
const emotion3 = require("../assets/emotion/3.PNG");
const emotion4 = require("../assets/emotion/4.PNG");
const emotion5 = require("../assets/emotion/5.PNG");
const emotion6 = require("../assets/emotion/6.PNG");
const emotion7 = require("../assets/emotion/7.PNG");
const emotion8 = require("../assets/emotion/8.PNG");
const emotion9 = require("../assets/emotion/9.PNG");

const halcon1 = require("../assets/tiles/HALCON1.PNG");
const halcon2 = require("../assets/tiles/HALCON2.PNG");
const halcon3 = require("../assets/tiles/HALCON3.PNG");
const halcon4 = require("../assets/tiles/HALCON4.PNG");
const halcon5 = require("../assets/tiles/HALCON5.PNG");
const halcon6 = require("../assets/tiles/HALCON6.PNG");
const halcon7 = require("../assets/tiles/HALCON7.PNG");
const halcon8 = require("../assets/tiles/HALCON8.PNG");
const halcon9 = require("../assets/tiles/HALCON9.PNG");

//projects

const jlt1 = require("../assets/project/JLT1.png");
const jlt2 = require("../assets/project/JLT2.png");
const jlt3 = require("../assets/project/JLT3.png");
const jlt4 = require("../assets/project/JLT4.png");
const jlt5 = require("../assets/project/JLT5.png");

const dnps = require("../assets/project/DNPS.png");

const tiles = [
  {
    name: "AZUVI",
    images: [
      azuvi1,
      azuvi2,
      azuvi3,
      azuvi4,
      azuvi5,
      azuvi6,
      azuvi7,
      azuvi8,
      azuvi9,
      azuvi10,
      azuvi11,
      azuvi12,
    ],
  },
  {
    name: "HALCON",
    images: [
      halcon1,
      halcon2,
      halcon3,
      halcon4,
      halcon5,
      halcon6,
      halcon7,
      halcon8,
      halcon9,
    ],
  },
  {
    name: "EMOTION",
    images: [
      emotion1,
      emotion2,
      emotion3,
      emotion4,
      emotion5,
      emotion6,
      emotion7,
      emotion8,
      emotion9,
    ],
  },
  {
    name: "ITT",
    images: [
      itt1,
      itt2,
      itt3,
      itt4,
      itt5,
      itt6,
      itt7,
      itt8,
      itt9,
      itt10,
      itt11,
      itt12,
    ],
  },
  {
    name: "ALAPLANA",
    images: [alap1, alap2, alap3, alap4, alap5, alap6, alap7, alap8, alap9],
  },
  {
    name: "ARKLAM",
    images: [
      arklam1,
      arklam2,
      arklam3,
      arklam4,
      arklam5,
      arklam6,
      arklam7,
      arklam8,
      arklam9,
      arklam10,
      arklam11,
      arklam12,
      arklam13,
      arklam14,
      arklam15,
      arklam16,
    ],
  },
];

const mixers = [
  {
    name: "DANIEL",
    images: [
      mixers1,
      mixers2,
      mixers3,
      mixers4,
      mixers5,
      mixers6,
      mixers7,
      mixers8,
      mixers9,
      mixers10,
      mixers11,
      mixers12,
      mixers13,
      mixers14,
      mixers15,
      mixers16,
    ],
  },
];
const mosaics = [
  {
    name: "SQUAMERS",
    images: [
      mosaics1,
      mosaics2,
      mosaics3,
      mosaics4,
      mosaics5,
      mosaics6,
      mosaics7,
      mosaics8,
      mosaics9,
      mosaics10,
      mosaics11,
      mosaics12,
    ],
  },
];
const bathtubs = [
  {
    name: "Harvey Moller",
    images: [
      bath1,
      bath2,
      bath3,
      bath4,
      bath5,
      bath6,
      bath7,
      bath9,
      bath10,
      bath11,
      bath12,
    ],
  },
];
const accessories = [
  {
    name: "Harvey Moller",
    images: [
      bathroomacc3,
      bathroomacc4,
      bathroomacc5,
      bathroomacc6,
      bathroomacc7,
      bathroomacc8,
      bathroomacc9,
      bathroomacc10,
    ],
  },
  {
    name: "Daniel",
    images: [
      bathroomacc1,
      bathroomacc2,
      bathroomacc3,
      bathroomacc11,
      bathroomacc12,
      bathroomacc14,
      bathroomacc15,
      bathroomacc16,
      bathroomacc17,
      bathroomacc18,
      bathroomacc19,
      bathroomacc20,
      bathroomacc21,
      bathroomacc22,
      bathroomacc23,
      bathroomacc24,
      bathroomacc25,
      bathroomacc26,
    ],
  },
];
const furniture = [
  {
    name: "Harvey Moller",
    images: [
      furniture1,
      furniture2,
      furniture3,
      furniture4,
      furniture5,
      furniture6,
      furniture7,
      furniture8,
      furniture9,
      furniture10,
      furniture11,
      furniture12,
    ],
  },
];

//Projects

const projects = [
  {
    name: "JLT CLinic Dubai",
    images: [jlt1, jlt2, jlt3, jlt4, jlt5],
  },
  {
    name: "Dr. Nutrition Project Sharjah",
    images: [dnps],
  },
];
module.exports = {
  tiles,
  mixers,
  mosaics,
  bathtubs,
  accessories,
  furniture,
  projects,
};
