import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Container fluid className="p-0">
      <Row
        className="m-0 p-0"
        style={{
          position: "fixed",
          width: "100vw",
          bottom: 0,
          backgroundColor: "black",
          color: "white",
          zIndex: "16",
        }}
      >
        <Col className="text text-center p-1">
          {/* © 2021 Alsatari Trading Est. All Rights Reserved. Developed by iTech
          Experts */}
          {t("footer")}
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
