import { Fragment } from "react";
import { Suspense } from "react";
import { Route } from "react-router";
import publicRoutes from "./routes/index";
import Loader from "react-loader-spinner";

const App = () => {
  return publicRoutes.map((route, index) => {
    return (
      <Fragment key={index}>
        <Suspense
          fallback={
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                mozTransform: "translateX(-50%) translateY(-50%)",
                webkitTransform: "translateX(-50%) translateY(-50%)",
                transform: "textDecorationThickness",
              }}
            >
              <Loader type="Puff" color="#00BFFF" height={100} width={100} />
            </div>
          }
        >
          <Route exact path={route.path} component={route.component} />
        </Suspense>
      </Fragment>
    );
  });
};

export default App;
