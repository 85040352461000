import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import brandLogo from "../../assets/logo/brand.png";
import "./NavPanel.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import alpana from "../../assets/catalogs/alpana.pdf";
import azuvi from "../../assets/catalogs/azuvi.pdf";
import emotion from "../../assets/catalogs/emotion.pdf";
import generalArklam from "../../assets/catalogs/generalArklam.pdf";
import halcon from "../../assets/catalogs/halcon.pdf";
import ittCeramicas from "../../assets/catalogs/ittCeramicas.pdf";
import daniel from "../../assets/catalogs/daniel.pdf";
import harveyMoller from "../../assets/catalogs/harveyMoller.pdf";
import facebook from "../../assets/social/facebook.png";
import instagram from "../../assets/social/instagram.png";
import youtube from "../../assets/social/youtube.png";

import { useHistory } from "react-router-dom";

const NavPanel = () => {
  const { i18n, t } = useTranslation();
  document.body.dir = i18n.dir();
  const handleChange = () => {
    i18n.translator.language === "ar"
      ? i18n.changeLanguage("en")
      : i18n.changeLanguage("ar");
  };

  const history = useHistory();

  const handlePdf = (path, doc) => {
    history.push({
      pathname: path,
      state: doc,
    });
  };

  const alignment = {
    textAlign: i18n.dir() === "ltr" ? "left" : "right",
  };

  return (
    <section style={{ position: "sticky", top: 0, zIndex: 16 }}>
      <Nav fill className="justify-content-center upper-nav pt-1 pb-1">
        {/* <Nav.Item className="justify-content-start m-0">
          <span className="mr-2 ml-2">info@alsatari.com</span>
          <span className="mr-2 ml-2">+971-586224616</span>
        </Nav.Item> */}

        <Nav.Item className="text-right mr-5">
          <span className="mr-2 ml-2">{t("header.contact")}</span>
          <span className="mr-2 ml-2">
            <a href="https://www.instagram.com/alsatari_group/" target="blank">
              <img
                src={instagram}
                loading="lazy"
                alt="facebook"
                style={{ height: "25px" }}
              />
            </a>
          </span>
          <span className="mr-2 ml-2">
            <a
              href="https://m.facebook.com/Alsatari-Group-%D8%A7%D9%84%D8%B3%D8%B7%D8%B1%D9%8A-%D9%84%D9%84%D8%B3%D8%B1%D8%A7%D9%85%D9%8A%D9%83-110301167944209/"
              target="blank"
            >
              <img
                src={facebook}
                loading="lazy"
                alt="twitter"
                style={{ height: "25px" }}
              />
            </a>
          </span>
          <span className="mr-2 ml-2">
            <a
              href="https://youtube.com/channel/UCN0TU6RCs0jAsBZ0AsGffig"
              target="blank"
            >
              <img
                src={youtube}
                loading="lazy"
                alt="youtube"
                style={{ height: "25px" }}
              />
            </a>
          </span>
        </Nav.Item>
      </Nav>
      <Navbar
        className="pl-5 pr-5 pt-4 lower-nav"
        collapseOnSelect
        expand="lg"
        sticky="top"
        // bg="dark"
        // variant="dark"
      >
        <Navbar.Brand as={Link} to="/">
          <img
            id="brandLogo"
            className="img-fluid"
            src={brandLogo}
            alt="brand"
            style={{ height: "15vh" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          className="justify-content-end"
          id="responsive-navbar-nav"
        >
          <NavDropdown
            className="dark"
            title={<span className="dark">{t("header.menu1.title")}</span>}
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item style={alignment}>
              <Nav.Link as={Link} to="/collections/tiles">
                {t("header.menu1.item1")}
              </Nav.Link>
            </NavDropdown.Item>
            <NavDropdown.Item style={alignment}>
              <Nav.Link as={Link} to="/collections/mosaics">
                {t("header.menu1.item2")}
              </Nav.Link>
            </NavDropdown.Item>
            <NavDropdown.Item style={alignment}>
              <Nav.Link as={Link} to="/collections/mixers">
                {t("header.menu1.item3")}
              </Nav.Link>
            </NavDropdown.Item>
            <NavDropdown.Item style={alignment}>
              <Nav.Link as={Link} to="/collections/accessories">
                {t("header.menu1.item4")}
              </Nav.Link>
            </NavDropdown.Item>
            <NavDropdown.Item style={alignment}>
              <Nav.Link as={Link} to="/collections/tubs">
                {t("header.menu1.item5")}
              </Nav.Link>
            </NavDropdown.Item>
            <NavDropdown.Item style={alignment}>
              <Nav.Link as={Link} to="/collections/furniture">
                {t("header.menu1.item6")}
              </Nav.Link>
            </NavDropdown.Item>
            {/* <NavDropdown.Divider />
            <NavDropdown.Item href="">4</NavDropdown.Item> */}
          </NavDropdown>
          <Nav.Link as={Link} to="/promotions" className="dark">
            {t("header.menu2")}
          </Nav.Link>
          <NavDropdown
            className="dark"
            title={<span className="dark">{t("header.menu3")}</span>}
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item as="a" href={alpana} target="blank">
              Alaplana
            </NavDropdown.Item>
            <NavDropdown.Item as="a" href={azuvi} target="blank">
              Azuvi
            </NavDropdown.Item>
            <NavDropdown.Item as="a" href={daniel} target="blank">
              Daniel
            </NavDropdown.Item>
            <NavDropdown.Item as="a" href={emotion} target="blank">
              Emotion
            </NavDropdown.Item>
            <NavDropdown.Item
              as="a"
              href={alpana}
              target="blank"
              onClick={() => handlePdf("/pdf/generalArklam", generalArklam)}
            >
              General Arklam
            </NavDropdown.Item>
            <NavDropdown.Item as="a" href={halcon}>
              Halcon
            </NavDropdown.Item>
            <NavDropdown.Item as="a" href={ittCeramicas} target="blank">
              ITT Ceramicas
            </NavDropdown.Item>
            <NavDropdown.Item as="a" href={harveyMoller} target="blank">
              harveyMoller
            </NavDropdown.Item>
          </NavDropdown>

          <Nav.Link as={Link} to="/projects" className="dark">
            {t("header.menu4")}
          </Nav.Link>
          {/* <Nav.Link className="dark">{t("header.menu5")}</Nav.Link> */}
          <Nav.Link className="dark">
            <b onClick={handleChange}>{t("header.menu6")}</b>
          </Nav.Link>
        </Navbar.Collapse>
      </Navbar>
    </section>
  );
};

export default NavPanel;
