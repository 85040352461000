import { Carousel, Container } from "react-bootstrap";
import c1 from "../../assets/carousal/c1.jpg";
import c2 from "../../assets/carousal/c2.jpg";
import c3 from "../../assets/carousal/c3.jpg";
import c4 from "../../assets/carousal/c4.jpg";
import c5 from "../../assets/carousal/c5.jpg";
import c6 from "../../assets/carousal/c6.jpg";

import "./HomeCarousel.css";

const carouselDetails = [
  { src: c1, h3: "", p: "" },
  { src: c2, h3: "", p: "" },
  { src: c3, h3: "", p: "" },
  { src: c4, h3: "", p: "" },
  { src: c5, h3: "", p: "" },
  { src: c6, h3: "", p: "" },
];

const HomeCarousel = () => {
  return (
    <Container fluid className="carouselContainer p-0 mb-5">
      <Carousel fade className="pl-5 pr-5 pb-5 shadow-lg">
        {carouselDetails.map((item, index) => {
          return (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100 border border-10 border-dark imgCarousel"
                src={item.src}
                alt="Alsatari Trading Carousal Slides"
                loading="lazy"
                style={{
                  backgroundSize: "cover",
                }}
              />
              <Carousel.Caption>
                <h3>{item.h3}</h3>
                <p>{item.p}</p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Container>
  );
};

export default HomeCarousel;
