import "./index.css";
import NavPanel from "../global/NavPanel";
import HomeCarousel from "../utils/HomeCarousel";
import { Container } from "react-bootstrap";
import Slider from "../utils/Slider";
import Footer from "../global/Footer";

const Home = () => {
  return (
    <Container fluid className="p-0">
      <NavPanel />
      <HomeCarousel />
      <Slider />
      <Footer />
    </Container>
  );
};

export default Home;
