import NavPanel from "../global/NavPanel";
import Loader from "react-loader-spinner";
import { Container, Row, Col, Button } from "react-bootstrap";
import Footer from "../global/Footer";

import { useTranslation } from "react-i18next";

const Promotion = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className="p-0">
      <NavPanel />
      <Row className="m-0 pt-2 pb-2 text-center">
        <Col
          style={{
            position: "absolute",
            top: "60%",
            left: "50%",
            mozTransform: "translateX(-50%) translateY(-50%)",
            webkitTransform: "translateX(-50%) translateY(-50%)",
            transform: "textDecorationThickness",
          }}
        >
          <Loader type="TailSpin" color="#2170B2" height={100} width={100} />
          <h4 className="mt-4 mb-4">{t("promotions.text")}</h4>
          <p>
            <a
              href="mailto:info@alsatari.ae?subject=Al%20Satari%20Promotions"
              className="shadow p-2"
              style={{
                color: "white",
                backgroundColor: "#909090",
                borderRadius: "5px",
                textDecoration: "none",
              }}
            >
              {t("promotions.mail")}
            </a>
          </p>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default Promotion;
