import { Page } from "react-pdf";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import { pdfjs } from "react-pdf";
import { useState } from "react";
import NavPanel from "../global/NavPanel";
import { Container, Row, Col, Button } from "react-bootstrap";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/5.3.0/pdf.worker.js`;

const Viewer = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Container fluid className="p-0">
      <NavPanel />
      <Row className="m-0 pt-2 pb-2 text-center">
        <Col style={{ display: "flex", justifyContent: "center" }}>
          <Button className="m-1" size="sm" variant="success">
            <a
              className="text-white"
              href={props.location.state}
              target="blank"
            >
              Download
            </a>
          </Button>
          <Button
            variant="warning"
            className="m-1"
            size="sm"
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            Back
          </Button>
          <Button
            variant="warning"
            className="m-1"
            size="sm"
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            Next
          </Button>
        </Col>
      </Row>
      <Row className="m-0">
        <Col>
          <p className="text text-center">
            Page <b>{pageNumber}</b> of <b>{numPages}</b>
          </p>
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-5">
        {/* <Col className="p-0 m-0"></Col> */}
        <Col
          className="p-0 m-0"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Document
            className="shadow-lg"
            file={props.location.state}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Col>
        {/* <Col className="p-0 m-0"></Col> */}
      </Row>
    </Container>
  );
};

export default Viewer;
