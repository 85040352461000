import NavPanel from "../global/NavPanel";
import "./index.css";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,
} from "react-bootstrap";
import { useState } from "react";
//
import { projects } from "../../helper/items";
import Footer from "../global/Footer";
//
const Projects = (props) => {
  const [active, setActive] = useState("JLT CLinic Dubai");
  const [modalShow, setModalShow] = useState(false);
  const [enlarged, setEnlarged] = useState("");

  const showImages = () => {
    switch (props.location.pathname) {
      case "/projects": {
        return projects.map((jt) => {
          if (jt.name === active) {
            return jt.images.map((image) => {
              return (
                <Col lg={4} className="pb-3">
                  <Card.Img
                    className="shadow"
                    style={{ height: "200px", width: "250px" }}
                    src={image.default}
                    alt="loading..."
                    loading="auto"
                    onClick={() => {
                      setModalShow(true);
                      setEnlarged(image.default);
                    }}
                  />
                </Col>
              );
            });
          }
        });
      }

      default:
        return;
    }
  };

  const collectionMenu = () => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Projects</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((jt) => {
            return (
              <tr
                onClick={() => {
                  setActive(jt.name);
                  console.log(active);
                }}
              >
                <td>{jt.name}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const MyModal = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img
            src={props.image}
            style={{
              width: "100%",
              height: "100%",
            }}
            alt="loading..."
          />
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: "#909090" }} onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Container fluid className="p-0">
      <NavPanel />
      <Row className="m-0">
        <Col xl={3} lg={4} md={4} sm={12} className="p-0">
          <Card>
            <Card.Body className="text-center p-0 m-0">
              {collectionMenu()}
            </Card.Body>
          </Card>
        </Col>
        <Col className="p-0" xl={9} lg={8} md={8} sm={12}>
          <Card>
            <Card.Body>
              <Row className="m-2 text-center">{showImages()}</Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <MyModal
        show={modalShow}
        image={enlarged}
        onHide={() => setModalShow(false)}
      />
      <Footer />
    </Container>
  );
};

export default Projects;
