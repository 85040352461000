import "./index.css";
import NavPanel from "../global/NavPanel";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Footer from "../global/Footer";

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 24.705374469720642, lng: 55.13439147773185 }}
    >
      {props.isMarkerShown && (
        <>
          <Marker
            position={{ lat: 24.19062604320101, lng: 55.76086940332053 }}
          />
          <Marker
            position={{ lat: 24.481243736331432, lng: 54.3773691334207 }}
          />
          <Marker position={{ lat: 25.325148, lng: 55.400157 }} />
        </>
      )}
    </GoogleMap>
  ))
);

//
const About = () => {
  const { t } = useTranslation();

  const card = {
    minHeight: "35vh",
  };
  return (
    <Container fluid className="p-0">
      <NavPanel />
      <Row className="m-0">
        <Col className="p-0">
          <h1 id="about-heading">{t("about-page.heading")}</h1>
        </Col>
      </Row>
      <Row className="m-0">
        <Col className="p-0">
          <p id="about">{t("about-page.text")}</p>
        </Col>
      </Row>
      <Row className="m-0 text-center pb-3">
        <Col md={4}>
          <Card className="shadow">
            <Card.Body style={card}>
              <h4 style={{ color: "#2170B2" }}>
                {t("about-page.al-ain.title")}
                <h5>{t("about-page.al-ain.sub-title")}</h5>
              </h4>
              <p>
                {t("about-page.al-ain.phone")}
                <br />
                {t("about-page.al-ain.email")}
                <br />
                {t("about-page.al-ain.address")}
                <br />
                {t("about-page.al-ain.p-o-box")}
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="shadow">
            <Card.Body style={card}>
              <h4 style={{ color: "#2170B2" }}>
                {t("about-page.abu-dhabi.title")}
                <h5>{t("about-page.abu-dhabi.sub-title")}</h5>
              </h4>
              <p>
                {t("about-page.abu-dhabi.phone")}
                <br />
                {t("about-page.abu-dhabi.email")}
                <br />
                {t("about-page.abu-dhabi.address")}
                <br />
                {t("about-page.abu-dhabi.p-o-box")}
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="shadow">
            <Card.Body style={card}>
              <h4 style={{ color: "#2170B2" }}>
                {t("about-page.sharjah.title")}
                <h5>{t("about-page.sharjah.sub-title")}</h5>
              </h4>
              <p>
                {t("about-page.sharjah.phone")}
                <br />
                {t("about-page.sharjah.email")}
                <br />
                {t("about-page.sharjah.address")}
                <br />
                {t("about-page.sharjah.p-o-box")}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="m-0 text-center pb-3">
        <Col>
          <Card>
            <Card.Body>
              <MyMapComponent
                isMarkerShown
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBgsTQRjnBJq6At2AJwccAkpul-esW3XWI&libraries=geometry,places&callback=initMap"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `500px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                lt="25.325148"
                ln="55.400157 "
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default About;
